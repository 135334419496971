export const OPEN_ID = "openid"
export const PROFILE = "profile"
export const EMAIL = "email"
export const CREATE_SP = "create:space-providers"
export const CREATE_SPACES = "create:locations"
export const MANAGE_SP = "manage:space-providers"
export const MANAGE_SPACES = "manage:locations"
export const READ_SP = "read:space-providers"
export const READ_SPACES = "read:locations"

export const SCOPES = [
  OPEN_ID,
  PROFILE,
  EMAIL,
  CREATE_SP,
  CREATE_SPACES,
  MANAGE_SP,
  MANAGE_SPACES,
  READ_SP,
  READ_SPACES,
]
