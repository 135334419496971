import React, { createContext, useReducer } from "react"
import PropTypes from "prop-types"
import useActions from "./actions"

import reducer, { initialState } from "./reducer"

let SpaceContext = createContext({
  data: initialState,
  actions: {
    setSpaces: () => null,
    setActiveSpace: () => null,
  },
})

function SpaceContextProvider({ children }) {
  let [data, dispatch] = useReducer(reducer, initialState)

  const actions = useActions(data, dispatch)

  return (
    <SpaceContext.Provider value={{ data, actions }}>
      {children}
    </SpaceContext.Provider>
  )
}

SpaceContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
}

let SpaceContextConsumer = SpaceContext.Consumer

export { SpaceContext, SpaceContextProvider, SpaceContextConsumer }
