import React, { useContext, useEffect, useState } from "react"
import axios from "axios"
import jwtDecode from "jwt-decode"
import auth0 from "auth0-js"
import get from "lodash/get"
import config from "../config"
import { SCOPES } from "./scopes"

const p = (func) => {
  return (...params) => {
    return new Promise((res, rej) => {
      func(...params, (error, result) => {
        if (error) {
          return rej(error)
        }
        return res(result)
      })
    })
  }
}
const TOKEN_OFFSET = 300 // 5 minutes

const getUnixTimestamp = () => Math.round(new Date().getTime() / 1000)

const isTokenExpired = (decodedToken) => {
  const diff = decodedToken.exp - getUnixTimestamp() - TOKEN_OFFSET
  return diff <= 0
}

const isBrowser = typeof window !== "undefined"

export const Auth0Context = React.createContext()
export const useAuth = () => useContext(Auth0Context)
export const Auth0Provider = ({ children }) => {
  const auth0Client = new auth0.WebAuth({
    domain: config.auth0.domain,
    clientID: config.auth0.clientId,
    responseType: "token id_token",
    audience: config.auth0.audience,
    redirectUri: `${
      isBrowser ? window.location.origin : "https://admin.worksimply.com"
    }/login`,
    scope: SCOPES.join(" "),
  })
  const [isAuthenticated, setIsAuthenticated] = useState()
  const [user, setUser] = useState({})

  const [isSoAdmin, setIsSoAdmin] = useState(false)
  const [isSoManager, setIsSoManager] = useState(false)

  const [isApiUser, setIsApiUser] = useState(false)

  const [isWorksimplyAdmin, setIsWorksimplyAdmin] = useState(false)

  const [permissions, setPermissions] = useState([])
  const [loading, setLoading] = useState(true)
  // const [popupOpen, setPopupOpen] = useState(false);// Maybe use this variable to track popup state?
  const [accessToken, setAccessToken] = useState(null)

  const checker = (token = accessToken) => {
    if (!token) {
      return
    }
    try {
      if (token !== accessToken) {
        setAccessToken(token)
      }
      const result = jwtDecode(token)
      setPermissions(result.permissions)
      if (isTokenExpired(result)) {
        console.log("token expired")
        setIsAuthenticated(false)
      } else {
        // console.log("logged in")
        setIsAuthenticated(true)
      }
    } catch (e) {
      console.log("something went wrong before authenticating")
      setIsAuthenticated(false)
    }
  }
  useEffect(() => {
    const initAuth0 = async () => {
      const token = localStorage.getItem("accessToken")
      setAccessToken(token)
      try {
        const decodedToken = jwtDecode(token)
        setIsAuthenticated(!isTokenExpired(decodedToken))
      } catch (e) {
        localStorage.removeItem("accessToken")
        setIsAuthenticated(false)
      }

      // TODO: Check the purpose of this code:
      if (window.location.hash) {
        const result = await p(auth0Client.parseHash.bind(auth0Client))({
          hash: window.location.hash,
        })
        setAccessToken(result.accessToken)
        setUser(result.idTokenPayload)
        setIsAuthenticated(true)
        auth0Client.popup.callback()
        const token = result.accessToken
        localStorage.setItem("accessToken", token)
      }
      setLoading(false)
    }
    initAuth0().then(() => null)
    // eslint-disable-next-line
  }, [])

  useEffect(checker, [accessToken])

  const hasScope = (permission) => {
    return permissions.indexOf(permission) > -1
  }

  const makeRequest = async (url, data = {}, method = "POST") => {
    const options = {
      method: method,
      url: `${process.env.GATSBY_API_URL}/${url}`,
      mode: "cors",
      cache: "no-cache",
      data: JSON.stringify(data),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken || ""}`,
      },
    }

    const response = await axios(options)
      .then((res) => res.data)
      .catch(() => ({}))

    return response
  }

  const logout = () => {
    setAccessToken("")
    localStorage.removeItem("accessToken")
    setIsAuthenticated(false)
    setPermissions([])
    auth0Client.logout({ returnTo: window.location.origin })
  }

  useEffect(() => {
    if (!isAuthenticated || !permissions || permissions.length === 0) return
    makeRequest("user/data").then((res) => {
      setIsWorksimplyAdmin(
        !get(res, "space_operator_id", "some_dummy_id") &&
          hasScope("create:space-operators")
      )
      setIsSoAdmin(
        !!get(res, "space_operator_id", false) &&
          hasScope("manage:all-locations") &&
          hasScope("create:locations")
      )
      setIsSoManager(
        !!get(res, "space_operator_id", false) &&
          hasScope("manage:owned-locations")
      )
      setIsApiUser(hasScope("use:public-api"))
      setUser(res)
    })
  }, [isAuthenticated, permissions])

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        hasScope,
        user,
        setUser,
        loading,
        makeRequest,
        isWorksimplyAdmin,
        isSoAdmin,
        isSoManager,
        isApiUser,
        authorize: () =>
          auth0Client.popup.authorize(
            {
              redirectUrl: window.location.origin,
            },
            function (err, result) {
              checker(localStorage.getItem("accessToken"))
            }
          ),
        logout,
      }}
    >
      {typeof isAuthenticated !== "undefined" ? children : ""}
    </Auth0Context.Provider>
  )
}
