import React from "react"
import { SpaceContextProvider } from "./src/hooks/SpaceContext"
import { Auth0Provider } from "./src/auth/useAuth"
import { ToastContainer } from "react-toastify"

import { QueryClient, QueryClientProvider } from "react-query"

import "bootstrap/dist/css/bootstrap.min.css"
import "react-toastify/dist/ReactToastify.css"
import "react-datepicker/dist/react-datepicker.css"
import "./src/styles/base.less"
import "./src/styles/toastify.less"

const twentyFourHoursInMs = 1000 * 60 * 60 * 24

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
})

export const wrapRootElement = ({ element }) => (
  <QueryClientProvider client={queryClient}>
    <Auth0Provider>
      <ToastContainer autoClose={5000} hideProgressBar />
      <SpaceContextProvider>{element}</SpaceContextProvider>
    </Auth0Provider>
  </QueryClientProvider>
)
