import { SET_SPACES, SET_VALUES } from "./types";
// import { navigate } from "@reach/router";

const useActions = (prevState, dispatch) => {
  return {
    setSpaces: result => {
      dispatch({ type: SET_SPACES, payload: result })
    },
    setValues: payload => {
      dispatch({ type: SET_VALUES, payload })
    },
  }
}

export default useActions
