exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-add-calendar-js": () => import("./../../../src/pages/add-calendar.js" /* webpackChunkName: "component---src-pages-add-calendar-js" */),
  "component---src-pages-admin-approved-cafes-add-jsx": () => import("./../../../src/pages/admin/approved-cafes/add.jsx" /* webpackChunkName: "component---src-pages-admin-approved-cafes-add-jsx" */),
  "component---src-pages-admin-approved-cafes-index-jsx": () => import("./../../../src/pages/admin/approved-cafes/index.jsx" /* webpackChunkName: "component---src-pages-admin-approved-cafes-index-jsx" */),
  "component---src-pages-admin-approved-cafes-view-[id]-jsx": () => import("./../../../src/pages/admin/approved-cafes/view/[id].jsx" /* webpackChunkName: "component---src-pages-admin-approved-cafes-view-[id]-jsx" */),
  "component---src-pages-admin-bookings-index-jsx": () => import("./../../../src/pages/admin/bookings/index.jsx" /* webpackChunkName: "component---src-pages-admin-bookings-index-jsx" */),
  "component---src-pages-admin-bookings-meeting-rooms-jsx": () => import("./../../../src/pages/admin/bookings/meeting-rooms.jsx" /* webpackChunkName: "component---src-pages-admin-bookings-meeting-rooms-jsx" */),
  "component---src-pages-admin-categories-index-jsx": () => import("./../../../src/pages/admin/categories/index.jsx" /* webpackChunkName: "component---src-pages-admin-categories-index-jsx" */),
  "component---src-pages-admin-coupons-index-js": () => import("./../../../src/pages/admin/coupons/index.js" /* webpackChunkName: "component---src-pages-admin-coupons-index-js" */),
  "component---src-pages-admin-hubs-[id]-jsx": () => import("./../../../src/pages/admin/hubs/[id].jsx" /* webpackChunkName: "component---src-pages-admin-hubs-[id]-jsx" */),
  "component---src-pages-admin-locations-add-js": () => import("./../../../src/pages/admin/locations/add.js" /* webpackChunkName: "component---src-pages-admin-locations-add-js" */),
  "component---src-pages-admin-members-[id]-jsx": () => import("./../../../src/pages/admin/members/[id].jsx" /* webpackChunkName: "component---src-pages-admin-members-[id]-jsx" */),
  "component---src-pages-admin-members-index-jsx": () => import("./../../../src/pages/admin/members/index.jsx" /* webpackChunkName: "component---src-pages-admin-members-index-jsx" */),
  "component---src-pages-admin-pending-payments-index-jsx": () => import("./../../../src/pages/admin/pending-payments/index.jsx" /* webpackChunkName: "component---src-pages-admin-pending-payments-index-jsx" */),
  "component---src-pages-admin-space-operators-edit-index-js": () => import("./../../../src/pages/admin/space-operators/edit/index.js" /* webpackChunkName: "component---src-pages-admin-space-operators-edit-index-js" */),
  "component---src-pages-admin-space-operators-index-js": () => import("./../../../src/pages/admin/space-operators/index.js" /* webpackChunkName: "component---src-pages-admin-space-operators-index-js" */),
  "component---src-pages-admin-space-operators-manage-js": () => import("./../../../src/pages/admin/space-operators/manage.js" /* webpackChunkName: "component---src-pages-admin-space-operators-manage-js" */),
  "component---src-pages-bookings-index-js": () => import("./../../../src/pages/bookings/index.js" /* webpackChunkName: "component---src-pages-bookings-index-js" */),
  "component---src-pages-calendar-availability-js": () => import("./../../../src/pages/calendar-availability.js" /* webpackChunkName: "component---src-pages-calendar-availability-js" */),
  "component---src-pages-enterprises-index-js": () => import("./../../../src/pages/enterprises/index.js" /* webpackChunkName: "component---src-pages-enterprises-index-js" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-location-[id]-jsx": () => import("./../../../src/pages/location/[id].jsx" /* webpackChunkName: "component---src-pages-location-[id]-jsx" */),
  "component---src-pages-locations-index-js": () => import("./../../../src/pages/locations/index.js" /* webpackChunkName: "component---src-pages-locations-index-js" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-operator-settings-index-js": () => import("./../../../src/pages/operator-settings/index.js" /* webpackChunkName: "component---src-pages-operator-settings-index-js" */),
  "component---src-pages-profile-js": () => import("./../../../src/pages/profile.js" /* webpackChunkName: "component---src-pages-profile-js" */),
  "component---src-pages-reports-index-jsx": () => import("./../../../src/pages/reports/index.jsx" /* webpackChunkName: "component---src-pages-reports-index-jsx" */),
  "component---src-pages-socials-index-jsx": () => import("./../../../src/pages/socials/index.jsx" /* webpackChunkName: "component---src-pages-socials-index-jsx" */),
  "component---src-pages-socials-manage-js": () => import("./../../../src/pages/socials/manage.js" /* webpackChunkName: "component---src-pages-socials-manage-js" */),
  "component---src-pages-tokens-index-js": () => import("./../../../src/pages/tokens/index.js" /* webpackChunkName: "component---src-pages-tokens-index-js" */),
  "component---src-pages-tours-index-js": () => import("./../../../src/pages/tours/index.js" /* webpackChunkName: "component---src-pages-tours-index-js" */),
  "component---src-pages-users-add-js": () => import("./../../../src/pages/users/add.js" /* webpackChunkName: "component---src-pages-users-add-js" */),
  "component---src-pages-users-edit-js": () => import("./../../../src/pages/users/edit.js" /* webpackChunkName: "component---src-pages-users-edit-js" */),
  "component---src-pages-users-index-js": () => import("./../../../src/pages/users/index.js" /* webpackChunkName: "component---src-pages-users-index-js" */)
}

