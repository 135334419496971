import { SET_SPACES, SET_VALUES } from "./types"

export const initialState = {
  list: [],
  user: {},
  users: [],
  refresh: true,
  spacesLoaded: false,
  toursBooked: [],
  allTours: [],
  soData: {},
  customHolidays: [],
  searchQueries: [],
  unallocatedSpaces: [],
  unallocatedUsers: [],
  spaceProviders: [],
  locationsCategorizedBySpaceOperator: [],
  mapCenter: { lat: 43.6508797, lng: -79.3834781 },
  allDesks: [],
  allOffices: [],
  tourChartData: null,
}

let reducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SPACES:
      return {
        ...state,
        list: action.payload.list,
        spacesLoaded: true,
        toursBooked: action.payload.toursBooked,
        searchQueries: action.payload.searchQueries,
      }
    case SET_VALUES:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return { ...state }
  }
}

export default reducer
